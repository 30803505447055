import io from "socket.io-client";
import feathers from "@feathersjs/client";
import socketio from '@feathersjs/socketio-client';
import { restClient, authClient } from "ra-data-feathers";

//const socket = io('http://localhost:3030', {
const socket = io('https://api.busserz.com', {  
  transports: ['websocket'],
  forceNew: true
});

const restClientOptions = {
  id: "_id", // In this example, the database uses '_id' rather than 'id'
  usePatch: true, // Use PATCH instead of PUT for updates
};

const authClientOptions = {
  storageKey: "feathers-jwt"
};

const ws = feathers().configure(socketio(socket)).configure(feathers.authentication({jwtStrategy: 'jwt', storage: window.localStorage}));

const dataProvider = restClient(ws, restClientOptions);
const authProvider = authClient(ws, authClientOptions);

export { dataProvider, authProvider };
