import { authProvider, dataProvider } from "./fsClient";
import { Admin, Resource } from 'react-admin';
import { EmojiPeople, Fastfood } from '@material-ui/icons';
//import dataP from "./fsClient/realm";
//import { ErrandList, ErrandShow } from './components/Errands';
import { ProductCreate, ProductEdit, ProductList} from './components/Products';
import { UserList, UserShow, UserCreate, UserEdit } from "./components/Users";

function App() {
  return (
    <Admin title="Busserz" dataProvider={dataProvider} authProvider={authProvider} >
      <Resource name="products" options={{ label: 'Products' }} list={ProductList} create={ProductCreate} edit={ProductEdit} icon={Fastfood}/>
      <Resource name="users" list={UserList} show={UserShow} create={UserCreate} edit={UserEdit} icon={EmojiPeople} />
    </Admin>
  );
}

export default App;