import React from "react";
import {
  BooleanInput,
  Edit,
  NumberInput,
  TextInput,
  maxLength,
  number,
  required,
  SimpleForm,
  TopToolbar,
  ListButton
} from "react-admin";


const validatePrice = [number("Får endast vara nummer"), required("Får inte vara tomt")];
const validateName = [
  required("Måste finnas ett namn"),
  maxLength(50, "Får inte vara längre än 50 tecken")
];

const EditActions = ({ basePath }) => (
  <TopToolbar>
      <ListButton basePath={basePath} />
  </TopToolbar>
);

const ProductEdit = (props) => { 
  return (    
    <Edit title="Edit" actions={<EditActions />} {...props}>
      <SimpleForm redirect="edit">
        <TextInput label="Name" source="name" validate={validateName} fullWidth helperText="Max 50 Characters" />
        <TextInput label="Description" source="desc" fullWidth />
        <NumberInput lable="Price" source="price" validate={validatePrice} helperText="Must be a number"/>
        <BooleanInput label="Product Active" source="active" />
      </SimpleForm>
    </Edit>
  );
};

export default ProductEdit;

