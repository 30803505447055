import * as React from 'react';

import { TopToolbar, ListButton, ShowButton, Show, List, Create, Edit, Datagrid, TextField, PasswordInput, TextInput, BooleanInput, BooleanField, DateField, EmailField, SimpleShowLayout, FunctionField, SimpleForm } from 'react-admin';

const UserEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
);

export const UserList = (props) => (
    <List {...props}  >
        <Datagrid rowClick="show">
            <FunctionField label="Name" render={record => `${record.firstName} ${record.lastName}`} />;
            <TextField source="mobilePhone" label="Mobil"/>
            <EmailField source="email" label="Mail" />
            <BooleanField source="active" />
        </Datagrid>
    </List>
);

export const UserShow = (props) => (
    <Show {...props}  >
        <SimpleShowLayout rowClick="edit">
            <FunctionField label="Name" render={record => `${record.firstName} ${record.lastName}`} />;
            <TextField source="mobilePhone" label="Mobil"/>
            <EmailField source="email" label="Mail" />
            <BooleanField source="active" />
            <DateField source="createdAt" label="Created Date" showTime />
        </SimpleShowLayout>
    </Show>
);

export const UserCreate = (props) => (
    <Create actions={<UserEditActions />} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="firstName" label="First Name"/>
            <TextInput source="lastName" label="Last Name" />
            <TextInput source="mobilePhone" label="Mobile Phone" />
            <TextInput source="email" type="email" label="Email" />
            <PasswordInput source="password" />
        </SimpleForm>
    </Create>
);

export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="firstName" label="First Name"/>
            <TextInput source="lastName" label="Last Name" />
            <TextInput source="mobilePhone" label="Mobile Phone" />
            <TextInput source="email" type="email" label="Email" />
            <PasswordInput source="password" />
            <BooleanInput source="active" />
        </SimpleForm>
    </Edit>
);

