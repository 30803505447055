import React from "react";
import {
  BooleanField,
  Datagrid,
  List,
  NumberField,
  TextField
} from "react-admin";


function ProductList(props) {
  return (
    <List title="Dagens" {...props} bulkActionButtons={false} perPage={120} sort={{ field: 'name', order: 'ASC' }} exporter={false}>
      <Datagrid rowClick="edit">
        <TextField label="Name" source="name" />
        <NumberField lable="Price" source="price" />
        <BooleanField label="Status" source="active" />
      </Datagrid>
    </List>
  );
}

export default ProductList;
