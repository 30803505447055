import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  maxLength,
  number,
  required
} from "react-admin";

const validatePrice = [number("Får endast vara nummer"), required("Får inte vara tomt")];
const validateName = [
  required("Måste finnas ett namn"),
  maxLength(50, "Får inte vara längre än 50 tecken")
];

const ProductCreate = (props) => {
  return (
    <Create title="Create" {...props}>
      <SimpleForm>
        <TextInput label="Name" source="name" validate={validateName} fullWidth helperText="Max 50 Characters" />
        <TextInput label="Description" source="desc" fullWidth initialValue={null} />
        <NumberInput lable="Price" source="price" validate={validatePrice} helperText="Must be a number" />
        <BooleanInput label="Product Active" source="active" initialValue={false} />
      </SimpleForm>
    </Create>
  );
};

export default ProductCreate;
